import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Gallery from './Gallery';


const WorksSection = () => (
 
    <StaticQuery
      query={graphql`
        {
          allWorksYaml {
            edges {
              node {
                image {
                  id
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                      originalImg
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Gallery
          images={data.allWorksYaml.edges.map(({ node }: any) => ({
            id: node.image.id,
            ...node.image.childImageSharp.fluid,
            // caption: `${node.title}`,
          }))}
          itemsPerRow={[2, 3]}
        />
      )}
    />
  
);

export default WorksSection;
