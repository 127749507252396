import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WorksSection from '../components/WorksSection';





const GalleryPage = () => {




      return(
  <Layout>
    <SEO title="Gallery" />
    <div className="container-2 gallery-con">
    <WorksSection/>
    </div>
    


        
  </Layout>
      )
  
}



export default GalleryPage
